import { Button } from "@shopify/polaris";
import React, { ReactElement, useMemo } from "react";

export function ButtonWithAnyChildren({ children, className, style, ...otherProps }: any): ReactElement {
  const finalStyle = useMemo(() => style || {}, [style]);

  return (
    <div className={className || ""} style={finalStyle}>
      <Button {...otherProps}>{children}</Button>
    </div>
  );
}
